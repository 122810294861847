<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item,i) in songs"
        :key="i"
      >
        <v-expansion-panel-header expand-icon="expand_more">
          {{ songs[i].name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form>
            <v-text-field
              v-model="songs[i].name"
              label="Titel"
            />
            <v-btn
              block
              class="ma-2"
              color="primary"
              @click="search(i)"
            >
              Zoeken
            </v-btn>
            <v-row>
              <v-col cols="1">
                <v-switch
                  v-model="songs[i].bpmEnabled"
                />
              </v-col>
              <v-col cols="11">
                <v-text-field
                  v-model="songs[i].bpm"
                  label="BPM"
                  :disabled="!songs[i].bpmEnabled"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-for="k in songs[i].queries.length"
              :key="k"
              v-model="songs[i].queries[k-1]"
              :label="`Zoekopdracht #${k}`"
              :append-outer-icon="songs[i].queries.length == k ? 'add_circle' : ''"
              @click:append-outer="newQuery(i)"
              @mousedown="selectedQuery = k"
            />
            <div class="text-center">
              <v-btn
                v-for="key in keys"
                :key="key"
                class="ma-1"
                depressed
                color="primary"
                @click="updateQuery(key, i)"
              >
                {{ key }}
              </v-btn>
              <v-btn
                block
                class="ma-2"
                color="primary"
                @click="resetQuery(i)"
              >
                Reset
              </v-btn>
              <v-btn
                block
                class="ma-2"
                color="primary"
                @click="remove(i)"
              >
                Verwijderen
              </v-btn>
              <v-btn
                block
                class="ma-2"
                color="primary"
                @click="save()"
              >
                Opslaan
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      block
      class="ma-2"
      color="primary"
      @click="goToSearch"
    >
      Nieuw nummer toevoegen
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      api: {
        type: String,
        default: ""
      },
      songs: {
        type: Array,
        default: function () { return [] }
      },
    },
    data: () => ({
      keys: ['A', 'B', 'C', 'D', 'E', 'F', 'G', '♭', '♭♭', ' ', '♯', '♯♯', ',', 'Δ', '7', 'mi7', 'ø', 'Δ+5', 'miΔ', 'o', '7♭9', '7♭13', '7♯11', '7alt'],
    }),
    methods: {
      emitHttpError: function(e){
        this.$emit("httpError", e)
      },
      newQuery: function (i) {
        this.songs[i].queries.push("")
        this.selectedQuery = this.songs[i].queries.length
      },
      search: function(i) {
        // TODO: queries verifiëren
        if (this.songs[i].bpmEnabled && this.songs[i].bpm && this.songs[i].bpm > 0){
          this.$emit("search", [this.songs[i].queries, this.songs[i].bpm])
        } else {
          this.$emit("search", [this.songs[i].queries, undefined])
        }
      },
      updateQuery: function (x, i) {
        this.songs[i].queries[this.selectedQuery - 1] += x
        this.$forceUpdate()
      },
      resetQuery: function (i) {
        this.songs[i].queries[this.selectedQuery - 1] = ''
        this.$forceUpdate()
      },
      goToSearch: function () {
        this.$router.push("/")
      },
      remove: function (i) {
        let self = this;
        this.songs.splice(i, 1)
        this.axios.post(`${this.api}/song/`, this.songs).then(function() {
          self.$emit("showSnackbar", "Verwijderd!")
        }).catch(function (error){
          self.$emit("showSnackbar", error.response.data)
        })
      },
      save: function () {
        let self = this;
        this.axios.post(`${this.api}/song/`, this.songs).then(function() {
          self.$emit("showSnackbar", "Opgeslagen!")
        }).catch(function (error){
          self.$emit("showSnackbar", error.response.data)
        })
      },
    }
  }
</script>
