<template>
  <v-app
    id="inspire"
    v-resize="onResize"
  >
    <v-navigation-drawer
      v-model="drawer"
      app
      :disable-route-watcher="this.$router.currentRoute.path.includes('search')"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Navigatie
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list
        nav
      >
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          :to="item.routerLink"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-btn
        class="ml-1"
        icon
        @click="drawer = !drawer"
      >
        <v-icon>
          menu
        </v-icon>
      </v-btn>

      <v-toolbar-title>Het Periodiek Systeem der Harmonieën</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="$vuetify.theme.dark = !$vuetify.theme.dark"
      >
        <v-icon>brightness_6</v-icon>
      </v-btn>
      <v-menu
        v-if="username != ''"
        top
        :close-on-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            color="indigo"
            size="36"
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ username.charAt(0) }}</span>
          </v-avatar>
        </template>

        <v-btn @click="logout()">
          Logout
        </v-btn>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
          >
            <v-sheet
              rounded="lg"
            >
              <v-container>
                <router-view
                  v-if="autoLoginDone"
                  :desktop="desktop"
                  :api="api"
                  :username="username"
                  :songs="songs"
                  @fetchData="autoLogin"
                  @search="onSearch"
                  @nextQuery="onNextQuery"
                  @httpError="showSnackbar"
                  @showSnackbar="showSnackbar"
                />
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="snackbarVisible"
      >
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarVisible = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    data: () => ({
      /*api: "https://psdh.beune.dev/api",*/
      api: process.env.VUE_APP_API_ENDPOINT,
      menuItems: [
        { title: 'Zoeken', routerLink: '/', icon: 'search' },
        { title: 'Bibliotheek', routerLink: '/library', icon: 'library_music' },
      ],
      loggedIn: false,
      desktop: true,
      drawer: false,
      snackbarVisible: false,
      snackbarMessage: "",
      queries: [],
      queryIndex: 0,
      username: "",
      songs: [],
      autoLoginDone: false,
    }),
    computed: {
    },
    mounted: function () {
      this.autoLogin()
      this.onResize()
      this.drawer = this.desktop
    },
    methods: {
      onResize: function () {
        this.desktop = window.innerWidth > 600
      },
      onSearch: async function (data) {
        this.queries = data[0]
        let milliseconds = 60 / (data[1] / 4) * 1000
        // NaN is the only value that is not equal to itself
        if (milliseconds !== milliseconds || this.queries.length <= 1){
          this.$router.push(`/search/${this.queries[0]}/0/0/0`)
        } else {
          const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
          this.onNextQuery(0)
          // TODO this for loop is hacky
          for (let i = 0; i < 1000; i++){
            if (!this.$router.currentRoute.path.includes("search")) break
            this.onNextQuery(1)
            await sleep(milliseconds)
          }

        }
      },
      onNextQuery: function (x){
        let queryIndex = this.indexModulus(this.queries.length, this.queryIndex + parseInt(x))
        this.queryIndex = queryIndex
        let synonym = this.$route.params.synonym ?? 0
        let sheet = this.$route.params.sheet ?? 0
        let index = this.$route.params.index ?? 0
        this.$router.push(`/search/${this.queries[queryIndex]}/${synonym}/${sheet}/${index}`)
      },
      indexModulus: function (length, index) {
        if (length === 1) return 0
        if(index >= 0){
          return index % length
        } else {
          return -(-index % length) + length
        }
      },
      showSnackbar: function (message){
        this.snackbarMessage = message;
        // Make it false first, so if multiple snackbars are visible the new one will start with a 'fresh' timeout period
        this.snackbarVisible = false;
        this.snackbarVisible = true;
      },
      logout: function () {
        let self = this;
        this.axios.post(`${this.api}/auth/logout/`).then(function() {
          self.username = ""
          self.songs = []
        }).catch(function (error){
          self.showSnackbar(error.response.data)
        })
      },
      autoLogin: function () {
        let self = this;
        this.axios.get(`${this.api}/user/`).then(function(response) {
          self.username = response.data.name ?? ""
          self.songs = response.data.songs ?? []
          self.autoLoginDone = true;
        }).catch(function (error){
          self.autoLoginDone = true;
          if (!error.response.data.includes("Unauthorized or not logged in")) {
            self.showSnackbar(error.response.data)
          }
        })
      }
    }
  }
</script>
