<template>
  <div>
    <v-sheet
      v-if="desktop"
      class="text-center"
      rounded="lg"
    >
      <v-btn
        color="primary"
        class="ma-2"
        @click="nextSynonym(1)"
      >
        <v-icon>
          arrow_back
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="ma-2"
        @click="nextSheet(1)"
      >
        <v-icon>
          arrow_forward
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="ma-2"
        @click="nextIndex(1)"
      >
        <v-icon>
          arrow_upward
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="ma-2"
        @click="nextIndex(-1)"
      >
        <v-icon>
          arrow_downward
        </v-icon>
      </v-btn>
    </v-sheet>
    <div
      v-if="data"
      v-touch="{
        up: () => nextIndex(1),
        down: () => nextIndex(-1),
        left: () => nextSynonym(1),
        right: () => nextSheet(1)
      }"
    >
      <v-simple-table
        dense
      >
        <tbody
          class="text-center"
        >
          <tr
            v-for="(column, index) in data['tableData']"
            :key="index"
            class="fix-width"
          >
            <td
              :style="{ backgroundColor: column[0]['color'], color: $vuetify.theme.dark && column[0]['color'] == '' ? 'white' : 'black' }"
              class="fix-width"
              @click="nextQuery(-1)"
            >
              {{ column[0]['data'] }}
            </td>
            <td
              :style="{ backgroundColor: column[1]['color'], color: $vuetify.theme.dark && column[1]['color'] == '' ? 'white' : 'black' }"
              class="fix-width"
            >
              {{ column[1]['data'] }}
            </td>
            <td
              :style="{ backgroundColor: column[2]['color'], color: $vuetify.theme.dark && column[2]['color'] == '' ? 'white' : 'black' }"
              class="fix-width"
              @click="nextQuery(1)"
            >
              {{ column[2]['data'] }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper {
  height: calc(80vh / 24);
}
.fix-width {
  max-width: 0px;
}
</style>
<script>
  export default {
    props: {
      desktop: {
        type: Boolean,
        default: true
      },
      api: {
        type: String,
        default: ""
      }
    },
    data: () => ({
      data: undefined,
    }),
    watch:{
      $route (){
        this.fetchTable()
      }
    },
    mounted: function() {
      this.fetchTable()
    },
    methods: {
      fetchTable: function() {
        let self = this
        this.axios.get(`${this.api}/query/${this.$route.params.query}/${this.$route.params.synonym}/${this.$route.params.sheet}/${this.$route.params.index}`).then(function(response) {
          self.data = response.data
        }).catch(function (error){
          if (error.response.data.includes("No results found")){
            self.$emit("showSnackbar", "Geen resultaten gevonden.")
          } else {
            self.$emit("httpError", error.response.data)
          }
          self.$router.push('/')
        })
      },
      nextIndex: function(x) {
        this.$router.push(`/search/${this.$route.params.query}/${this.$route.params.synonym}/${this.$route.params.sheet}/${parseInt(this.$route.params.index) + parseInt(x)}`)
      },
      nextSynonym: function(x) {
        this.$router.push(`/search/${this.$route.params.query}/${parseInt(this.$route.params.synonym) + parseInt(x)}/${this.$route.params.sheet}/${this.$route.params.index}`)
      },
      nextSheet: function(x) {
        this.$router.push(`/search/${this.$route.params.query}/${this.$route.params.synonym}/${parseInt(this.$route.params.sheet) + parseInt(x)}/${this.$route.params.index}`)
      },
      nextQuery: function(x) {
        this.$emit("nextQuery", x)
      }
    }
  }
</script>
