<template>
  <div>
    <Account
      v-if="username == ''"
      :api="api"
      @httpError="emitHttpError"
      @fetchData="fetchData"
      @showSnackbar="showSnackbar"
    />
    <Library
      v-else
      :songs="songs"
      :api="api"
      @search="emitSearch"
      @showSnackbar="showSnackbar"
    />
  </div>
</template>

<script>
  import Account from '../components/Account.vue'
  import Library from '../components/Library.vue'
  export default {
    components: {
      Account,
      Library
    },
    props: {
      api: {
        type: String,
        default: ""
      },
      songs: {
        type: Array,
        default: function () { return [] }
      },
      username: {
        type: String,
        default: ""
      }
    },
    data: () => ({
    }),
    methods: {
      fetchData: function(){
        this.$emit("fetchData")
      },
      emitHttpError: function(e){
        this.$emit("httpError", e)
      },
      emitSearch: function(e){
        this.$emit("search", e)
      },
      showSnackbar: function(e){
        this.$emit("showSnackbar", e)
      }
    }
  }
</script>
