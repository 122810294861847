<template>
  <div>
    <v-form>
      <v-row>
        <v-col cols="1">
          <v-switch
            v-model="bpmEnabled"
          />
        </v-col>
        <v-col cols="11">
          <v-text-field
            v-model="bpm"
            label="BPM"
            :disabled="!bpmEnabled"
          />
        </v-col>
      </v-row>
      <v-text-field
        v-for="i in queries.length"
        :key="i"
        v-model="queries[i-1]"
        :label="`Zoekopdracht #${i}`"
        :append-outer-icon="queries.length == i ? 'add_circle' : ''"
        @click:append-outer="newQuery"
        @mousedown="selectedQuery = i"
      />
      <div class="text-center">
        <v-btn
          v-for="key in keys"
          :key="key"
          class="ma-1"
          depressed
          color="primary"
          @click="updateQuery(key)"
        >
          {{ key }}
        </v-btn>
        <v-btn
          block
          class="ma-2"
          color="primary"
          @click="resetQuery()"
        >
          Reset
        </v-btn>
        <v-btn
          block
          class="ma-2"
          color="primary"
          @click="search"
        >
          Zoeken
        </v-btn>
        <v-btn
          v-if="username !== ''"
          block
          class="ma-2"
          color="primary"
          @click="addToLibrary"
        >
          Toevoegen aan bibliotheek
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
  export default {
    props: {
      username: {
        type: String,
        default: ""
      },
      songs: {
        type: Array,
        default: function () { return [] }
      },
      api: {
        type: String,
        default: ""
      }
    },
    data: () => ({
      keys: ['A', 'B', 'C', 'D', 'E', 'F', 'G', '♭', '♭♭', ' ', '♯', '♯♯', ',', 'Δ', '7', 'mi7', 'ø', 'Δ+5', 'miΔ', 'o', '7♭9', '7♭13', '7♯11', '7alt'],
      queries: [""],
      selectedQuery: 1,
      bpm: undefined,
      bpmEnabled: false,
    }),
    methods: {
      search: function() {
        // TODO: queries verifiëren
        if (this.bpm && this.bpm > 500){
          this.$emit("showSnackbar", "Maximaal mogelijke BPM is 500.")
          return
        }
        if (this.bpmEnabled && this.bpm && this.bpm > 0){
          this.$emit("search", [this.queries, this.bpm])
        } else {
          this.$emit("search", [this.queries, undefined])
        }
      },
      updateQuery: function (x) {
        this.queries[this.selectedQuery - 1] += x
        this.$forceUpdate()
      },
      newQuery: function () {
        this.queries.push("")
        this.selectedQuery = this.queries.length
      },
      resetQuery: function () {
        this.queries[this.selectedQuery - 1] = ''
        this.$forceUpdate()
      },
      addToLibrary: function () {
        let self = this;
        let s = self.songs
        let o = {
          "name": "Naamloos",
          "queries": self.queries,
          "bpm": self.bpm,
          "bpmEnabled": self.bpmEnabled
        }
        s.push(o)
        this.axios.post(`${this.api}/song/`, s).then(function() {
          self.$emit("showSnackbar", "Nummer toegevoegd aan bibliotheek!")
        }).catch(function (error){
          self.$emit("showSnackbar", error.response.data)
        })
      },
    }
  }
</script>
