<template>
  <div>
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="username"
              :rules="[rules.required, rules.min]"
              label="Username"
              counter
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :rules="[rules.required, rules.min]"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              hint="At least 6 characters"
              counter
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
        <v-container class="text-center">
          Door te klikken op "Sign up" gaat u akkoord met ons <a @click="togglePrivacyPolicy">privacybeleid</a>.
          <br>
          <v-btn
            color="primary"
            @click="login"
          >
            Login
          </v-btn>
          <v-btn
            color="primary"
            @click="signup"
          >
            Sign up
          </v-btn>
        </v-container>
        <PrivacyPolicy
          v-if="showPrivacyPolicy"
        />
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import PrivacyPolicy from './PrivacyPolicy.vue'
  export default {
    components: {
      PrivacyPolicy
    },
    props: {
      api:{
        type: String,
        default: ""
      }
    },
    data: () => ({
      username: "",
      password: "",
      showPassword: false,
      showPrivacyPolicy: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters',
      },
    }),
    methods: {
      togglePrivacyPolicy: function () {
        this.showPrivacyPolicy = !this.showPrivacyPolicy;
      },
      login: function () {
        let self = this
        this.axios.post(`${this.api}/auth/login`, {
          name: this.username,
          pw: this.password
        }).then(function() {
          self.$emit("fetchData")
          self.$emit("showSnackbar", "Welkom!")
        }).catch(function (error){
          if (error.response.data.includes("Invalid combination of username and password.")){
            self.$emit("httpError", "Combinatie gebruikersnaam en wachtwoord onbekend.")
          }else {
            self.$emit("httpError", error)
          }
        })
      },
      signup: function () {
        let self = this
        this.axios.post(`${this.api}/user`, {
          name: this.username,
          pw: this.password
        }).then(function() {
          self.$emit("fetchData")
          self.$emit("showSnackbar", "Account aangemaakt!")
        }).catch(function (error){
          if (error.response.data.includes("Username already in use")){
            self.$emit("httpError", "Gebruikersnaam al in gebruik.")
          } else {
            self.$emit("httpError", error)
          }
        })
      },
    }

  }
</script>
